import React from 'react';
import Content from '../views/Driver/Price';

export default ({location}) => <Content location={location} seo={{
  title: 'Get started with a mobile app for your vehicle',
  lang: 'en',
  description: 'Keep a better overview of your car and track your trips. Sign up by following the instructions. Download the car mobile app. Benefit from it every day.',
  meta: [{
    name: 'keywords',
    content: 'Mobile app'
  }]
}}/>